<template>
  <div>
    <breadCrumb :title="$t('suo-you-fen-lei')" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="mb-3 bg-white shadow-sm rounded" v-for="(item, i) in categoryList" :key="i">
            <div class="p-3 border-bottom fs-16 fw-600" @click="toDetail(item)">
              <a  class="text-reset">{{  item.Name }}</a>
            </div>
            <div class="p-3 p-lg-4">
                <div class="row">
                    <div class="col-lg-4 col-6 text-left" v-for="(child, index) in item.children" :key="index">
                        <h6 class="mb-3"><a class="text-reset fw-600 fs-14" @click="toDetail(child, i)">{{ child.Name }}</a></h6>
                        <ul class="mb-3 list-unstyled pl-2">
                            <li class="mb-2" v-for="(category, m) in child.children" :key="m" @click="toDetail(category, i, index)">
                              <a class="text-reset">{{ category.Name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import { getCategoryList } from '@/api/index'
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: this.$t('suo-you-fen-lei'),
          path: ''
        },
      ],
    }
  },
  computed: {
    categoryList() {
      return this.$store.state.categoryList
    },
  },
  methods: {
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
    toDetail(data, i, index) {
      if (i != undefined && index != undefined) { // 第3级
        let categoryData = [
          {
            id: this.categoryList[i].ID,
            name: this.categoryList[i].Name,
          },
          {
            id: this.categoryList[i].children[index].ID,
            name: this.categoryList[i].children[index].Name,
          },
        ]
        sessionStorage.setItem('categoryData', JSON.stringify(categoryData))
      } else if (i != undefined) {
        let categoryData = [
          {
            id: this.categoryList[i].ID,
            name: this.categoryList[i].Name,
          },
        ]
        sessionStorage.setItem('categoryData', JSON.stringify(categoryData))
      } else {
        sessionStorage.setItem('categoryData', '[]')
      }
      this.$router.push({
        name: 'categoryDetail',
        query: {
          name: data.Name,
          id: data.ID
        }
      })
    }
  }
}
</script>